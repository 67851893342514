import { useState, useEffect, Fragment, FunctionComponent } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EmsModal from "@components/modal/emsModal";
import styles from "../globalAdjustment.module.scss";
import { useTranslation } from "react-i18next";
import { formatDate, getLocalTimeStrRep, getUnixTimestamp } from "@utils/common";
import {  useSignals } from "@ecamion/sucrose";

const SignalEvents = (props : any)  => {
  const { t:translation } = useTranslation();
  return props.sigEvents.map((item: any, index: number) => {

    return (
      <div key={index} className={styles.event_log__date__body_section}>
      <div className={[styles.modal_row, styles.paramText2].join(" ")}>
        <div className={styles.event_log__date_signal}>
          {`Signal  ${item.SignalType} - ${item.siteLabel}`}
        </div>
        <div>{item["TimeFormat"]}</div>
      </div>
      {item.SignalType === 1 ?(
        <div>
          <div className={[styles.modal_row, styles.paramText2].join(" ")}>
            <div>{translation("globalAdjustment.batteryReadyTime")}</div>
            <div>{item["BatteryReadyTimeFormat"]}</div>
          </div>
          <div className={[styles.modal_row, styles.paramText2].join(" ")}>
            <div>{translation("globalAdjustment.sig1ResetTime")}</div>
            <div>{item["Signal1ResetTimeFormat"]}</div>
          </div>
        </div>
      ) : (
        <div>
          <div className={[styles.modal_row, styles.paramText2].join(" ")}>
            <div>{translation("globalAdjustment.startTime")}</div>
            <div>{ item["StartTimeFormat"] }</div>
          </div>
          <div className={[styles.modal_row, styles.paramText2].join(" ")}>
            <div>{translation("globalAdjustment.dischargeDuration")}</div>
            <div>
              { item["DischargeDuration"] === null ? ' - ' : item["DischargeDuration"] }
            </div>
          </div>
          <div className={[styles.modal_row, styles.paramText2].join(" ")}>
            <div>{translation("globalAdjustment.percentESS")}</div>
            <div>
              { item["PercentEssGa"] === null ? ' - ' : item["PercentEssGa"] + "%" }
            </div>
          </div>
          <div className={[styles.modal_row, styles.paramText2].join(" ")}>
            <div>{translation("globalAdjustment.rechargePowerLevel")}</div>
            <div>
              { item["RechargePowerLevel"] === null ? ' - ' : item["RechargePowerLevel"] }
            </div>
          </div>
        </div>
      )}
    </div>
  );
})
}

const EventLogModal: FunctionComponent<EventLogProps> = (props) => {
  const { t: translation } = useTranslation();
  const [dates, setDates] = useState<any[]>([]);
  const [sigDateDataMap, setSigDateDataMap] = useState<any>({});

  let unixStart = getUnixTimestamp(new Date(2021, 7, 1)).toString();
  let unixEnd = getUnixTimestamp(new Date()).toString();

  const systemStates = useSelector((state: any) => {return {id: state.system.id,};});
  let lastSignals :any = useSignals([systemStates.id.toString()], unixStart, unixEnd, 5 *60 * 1000);

  useEffect(() => {
    if(lastSignals?.data?.[systemStates.id]){
      let allDates: string[] = [...dates];
      const newEventDateDataMap: any = {};
      Object.values(lastSignals?.data?.[systemStates.id] ?? {}).forEach((signalEvent : any, index: number) => {
        const date: string = formatDate(signalEvent.Time)!;
        // sets some formated dates and labels for the signal printing
        signalEvent.siteLabel = props.currentSystemLabel;
        signalEvent.TimeFormat = getLocalTimeStrRep(signalEvent["Time"]);
        signalEvent.StartTimeFormat = signalEvent.SignalType===2 ? getLocalTimeStrRep(signalEvent["StartTime"]) : "";
        signalEvent.BatteryReadyTimeFormat = signalEvent.SignalType===1 ? getLocalTimeStrRep(signalEvent["BatteryReadyTime"]): "" ;
        signalEvent.Signal1ResetTimeFormat = signalEvent.SignalType===1 ? getLocalTimeStrRep(signalEvent["Signal1ResetTime"]): "" ;
        //assigns or creates a new date group
        if (!(date in newEventDateDataMap)) {
          newEventDateDataMap[date] = [signalEvent];
        } else {
          newEventDateDataMap[date].push(signalEvent);
        }
        // sort the signal events in descending order
        newEventDateDataMap[date].sort((prev: any, curr: any) => {
          return new Date(curr.Time).getTime() - new Date(prev.Time).getTime();
        });
        if (allDates.indexOf(date) === -1) {
          allDates.push(date)
        };
      });
      setSigDateDataMap(newEventDateDataMap);
      allDates.sort((prev: any, curr: any) => {
        return new Date(curr).getTime() - new Date(prev).getTime();
      });
      setDates(allDates);
    }

  }, [lastSignals?.data]);

  const eventLogModalCloseHandler = () => {
    props.eventLogCloseHandler(false);
  };


  return (
    <EmsModal open={props.isEventLogModalOpen} modal_width={"35%"} modal_height={"60%"}>
      <div className={styles.event_log__container}>
        <div className={styles.event_log__top_container}>
          <div className={styles.event_log__header}>
            {translation("globalAdjustment.globalAdjustmentEventLog")}
          </div>
          <div className={styles.event_log__close_button}>
            <IconButton aria-label="close" onClick={eventLogModalCloseHandler}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.event_log__date_body}>
          {dates.length > 0 &&
            dates.map((date: string, fragmentIndex: number) => {
              return (
                <Fragment key={fragmentIndex}>
                  <div className={styles.event_log__date_header}>{date}</div>
                  <div className={styles.event_log__date_body}>
                    <SignalEvents sigEvents={ sigDateDataMap[date] ?? []}  />
                  </div>
                </Fragment>
              );
            })}
        </div>
      </div>
    </EmsModal>
  );
}


interface EventLogProps{
  isEventLogModalOpen: boolean;
  eventLogCloseHandler: Function;
  currentSystemLabel: string;
}

export default EventLogModal;