import styles from "./costProfile.module.scss";
import SubCard from "@pages/pricingConfig/components/subCard";
import { Spacer } from "@nextui-org/react";
import CostProfileSpec, {
  ConvertToTitle,
} from "@pages/pricingConfig/components/costProfileSpec";
import EditableCostProfile from "@pages/pricingConfig/components/editableCostProfile";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CostProfileActions from "@pages/pricingConfig/components/costProfileActions";
import { BsFillLightningFill } from "react-icons/bs";
import CodeModal from "@pages/pricingConfig/components/codeModal";
import CustomButton from "@pages/pricingConfig/components/customButton";
import { costProfileToJsonObj } from "@utils/pricingConfig";

export default function CostProfile({
  costProfile,
  showTitle = true,
  padding = 25,
  showEditButton = false,
  showActionButton = false,
}: any) {
  const [visible, setVisible] = useState(false);
  const [jsonVisible, setJsonVisible] = useState(false);
  const [costProfileFromDB, setCostProfileFromDB] = useState<any>();
  const [actionsVisible, setActionsVisible] = useState(false);
  const costProfiles = useSelector(
    (state: any) => state.costProfiles.costProfiles
  );
  useEffect(() => {
    setCostProfileFromDB(
      costProfile
        ? costProfiles.find((cp: any) => {
            return cp.id === costProfile.id;
          })
        : null
    );
  }, [costProfiles, costProfile]);
  const modifiable = costProfileFromDB ? costProfileFromDB.modifiable : false;
  const systemId = useSelector((state: any) => state.system.id);
  return (
    <>
      {costProfileFromDB ? (
        <>
          <EditableCostProfile
            setCostProfile={setCostProfileFromDB}
            isCreate={false}
            costProfile={costProfileFromDB}
            visible={visible}
            setVisible={setVisible}
          />
          <CostProfileActions
            visible={actionsVisible}
            setVisible={setActionsVisible}
            costProfile={costProfileFromDB}
          />
          <CodeModal
            title={`Cost Profile - ${costProfileFromDB.name}`}
            visible={jsonVisible}
            setVisible={setJsonVisible}
            code={JSON.stringify(
              costProfileToJsonObj(costProfileFromDB),
              null,
              2
            )}
          />
        </>
      ) : (
        <></>
      )}
      <div className={styles.root} style={{ padding: padding }}>
        {showTitle ? (
          <div className={styles.header}>
            {costProfile ? (
              <>Active @ System-{systemId}</>
            ) : (
              <>No Active Cost Profile Found</>
            )}
          </div>
        ) : (
          <></>
        )}
        <Spacer y={0.01} />
        {costProfile ? (
          Object.keys(CostProfileSpec).map((key: string) => {
            const spec: any = CostProfileSpec[key];
            const title = spec.title ?? ConvertToTitle(key);
            if (
              key === "version" &&
              costProfileFromDB &&
              showEditButton &&
              showActionButton
            ) {
              if (costProfile.version !== costProfileFromDB.version) {
                return (
                  <SubCard
                    key={key}
                    titleColor={"#F31260"}
                    title={`${title}: ${spec.prefix ? spec.prefix + "" : ""}${
                      costProfile[key]
                    }${spec.suffix ? spec.suffix + "" : ""} (Version ${
                      costProfileFromDB.version
                    } Available)`}
                    icon={spec.icon}
                  />
                );
              } else {
                return (
                  <SubCard
                    key={key}
                    title={`${title}: ${spec.prefix ? spec.prefix + "" : ""}${
                      costProfile[key]
                    }${spec.suffix ? spec.suffix + "" : ""} (LATEST)`}
                    icon={spec.icon}
                  />
                );
              }
            }
            return (
              <SubCard
                key={key}
                title={`${title}: ${spec.prefix ? spec.prefix + "" : ""}${
                  costProfile[key]
                }${spec.suffix ? spec.suffix + "" : ""}`}
                icon={spec.icon}
              />
            );
          })
        ) : (
          <></>
        )}

        <Spacer y={0.1} />
        {showEditButton && costProfile ? (
          <>
            <CustomButton
              onClick={() => {
                setJsonVisible(true);
              }}
            >
              View As JSON
            </CustomButton>
            <CustomButton
              disabled={!modifiable}
              onClick={() => {
                setVisible(true);
              }}
            >
              {`Edit This Profile ${modifiable ? "" : "(No Permission)"}`}
            </CustomButton>
          </>
        ) : (
          <></>
        )}
        {showActionButton && costProfile ? (
          <CustomButton
            icon={<BsFillLightningFill />}
            onClick={() => {
              setActionsVisible(true);
            }}
          >
            Actions / Update
          </CustomButton>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
