import { IOT_URL } from "../constants";
import axios, { AxiosInstance } from "axios";

export { FieldProvider, useFieldContext } from "./sse/fields";

export type {
  StringModules,
  GroupFieldValues,
  SECCs,
  StringModule
} from "./sse/types";

axios.defaults.withCredentials = true;

export const IoTAPI = axios.create({
  baseURL: `${IOT_URL}/`,
});

export const EnableSignalAPI = axios.create({
  baseURL: `${IOT_URL}/action/`,
});

// export const AliveSystemsAPI = axios.create({
//   baseURL: `${IOT_URL}/health/alive`,
// });

export const IoTV2API = axios.create({
  baseURL: `${IOT_URL}/v2/`,
});

export const IoTActionAPI = axios.create({
  baseURL: `${IOT_URL}/action/`,
});




export const query = async function (api: AxiosInstance, options: any) {
  // adding success and error handlers to client
  // console.log(api.getUri(), options)
  const response = await api(options);
  return response.data;
};
