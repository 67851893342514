import {
  Table,
  Row,
  Col,
  Tooltip,
  Text,
  styled,
} from "@nextui-org/react";
import { CostProfileSample } from "./costProfileSpec";
import EditableCostProfile from "@pages/pricingConfig/components/editableCostProfile";
import { useState } from "react";
import DeleteCostProfileModal from "@pages/pricingConfig/components/deleteCostProfileModal";
import styles from "./costProfileTable.module.scss";
import CostProfileActions from "@pages/pricingConfig/components/costProfileActions";
import { BsFillLightningFill } from "react-icons/bs";
import { AiFillEye, AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import CodeModal from "@pages/pricingConfig/components/codeModal";
import CustomButton from "@pages/pricingConfig/components/customButton";
import { costProfileToJsonObj } from "@utils/pricingConfig";

type status = "green" | "red" | "orange";

export const IconButton = styled("button", {
  dflex: "center",
  border: "none",
  outline: "none",
  cursor: "pointer",
  padding: "0",
  margin: "0",
  bg: "transparent",
  transition: "$default",
  "&:hover": {
    opacity: "0.8",
  },
  "&:active": {
    opacity: "0.6",
  },
});

export const StyledBadge = styled("span", {
  display: "inline-block",
  textTransform: "uppercase",
  padding: "$2 $3",
  margin: "0 2px",
  fontSize: "10px",
  fontWeight: "$bold",
  borderRadius: "14px",
  letterSpacing: "0.6px",
  lineHeight: 1,
  boxShadow: "1px 2px 5px 0px rgb(0 0 0 / 5%)",
  alignItems: "center",
  alignSelf: "center",
  color: "$white",
  variants: {
    type: {
      green: {
        bg: "$successLight",
        color: "$successLightContrast",
      },
      red: {
        bg: "$errorLight",
        color: "$errorLightContrast",
      },
      orange: {
        bg: "$warningLight",
        color: "$warningLightContrast",
      },
    },
  },
  defaultVariants: {
    type: "active",
  },
});

export default function CostProfileTable() {
  const costProfiles = useSelector(
    (state: any) => state.costProfiles.costProfiles
  );
  const [visible, setVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [jsonVisible, setJsonVisible] = useState(false);
  const [costProfileJSON, setCostProfileJSON] = useState(CostProfileSample);
  const [costProfile, setCostProfile] = useState(CostProfileSample);
  const [costProfileToDelete, setCostProfileToDelete] =
    useState(CostProfileSample);
  const [costProfileAction, setCostProfileAction] = useState();
  const [isCreate, setIsCreate] = useState(false);
  const columns = [
    { name: "NAME", uid: "name" },
    { name: "VERSION", uid: "version" },
    { name: "LAST MODIFIED", uid: "last_modified_time" },
    { name: "STATUS", uid: "status" },
    { name: "ACTIONS", uid: "actions" },
  ];
  const renderCell = (profile: any, columnKey: any) => {
    const cellValue = profile[columnKey];
    switch (columnKey) {
      case "last_modified_time":
        return <div>{new Date(cellValue).toLocaleDateString()}</div>;
      case "name":
        return <div>{cellValue}</div>;
      case "tax_rate":
        return <div>{cellValue}%</div>;
      case "status":
        let type: status = "red";
        let value: string = "Apply Only";
        if (profile.modifiable && profile.removable) {
          type = "green";
          value = "Full Access";
        } else if (profile.modifiable) {
          type = "orange";
          value = "No Remove";
        }
        return <StyledBadge type={type}>{value}</StyledBadge>;

      case "actions":
        return (
          <Row justify="center" align="center">
            <Col css={{ d: "flex" }}>
              <Tooltip content="View">
                <IconButton
                  onClick={() => {
                    setCostProfileJSON(costProfileToJsonObj(profile));
                    setJsonVisible(true);
                  }}
                >
                  <AiFillEye color="#979797" size={19} />
                </IconButton>
              </Tooltip>
            </Col>
            <Col css={{ d: "flex" }}>
              <Tooltip content="Apply">
                <IconButton
                  onClick={() => {
                    setCostProfileAction(profile);
                    setActionsVisible(true);
                  }}
                >
                  <BsFillLightningFill color="#979797" size={19} />
                </IconButton>
              </Tooltip>
            </Col>
            {profile.modifiable ? (
              <Col css={{ d: "flex" }}>
                <Tooltip content="Edit Profile">
                  <IconButton
                    onClick={() => {
                      setCostProfile(profile);
                      setIsCreate(false);
                      setVisible(true);
                    }}
                  >
                    <AiTwotoneEdit color="#979797" size={19} />
                  </IconButton>
                </Tooltip>
              </Col>
            ) : (
              <Col></Col>
            )}
            {profile.removable ? (
              <Col css={{ d: "flex" }}>
                <Tooltip
                  content="Delete Profile"
                  color="error"
                  onClick={() => {
                    setCostProfileToDelete(profile);
                    setDeleteVisible(true);
                  }}
                >
                  <IconButton>
                    <MdDeleteForever color="#979797" size={19} />
                  </IconButton>
                </Tooltip>
              </Col>
            ) : (
              <Col></Col>
            )}
          </Row>
        );
      default:
        return cellValue;
    }
  };
  return (
    <div className={styles.root}>
      <CodeModal
        title={`Cost Profile - ${costProfileJSON.name}`}
        visible={jsonVisible}
        setVisible={setJsonVisible}
        code={JSON.stringify(costProfileJSON, null, 2)}
      />
      <CostProfileActions
        visible={actionsVisible}
        setVisible={setActionsVisible}
        costProfile={costProfileAction}
      />
      <EditableCostProfile
        setCostProfile={setCostProfile}
        isCreate={isCreate}
        costProfile={costProfile}
        visible={visible}
        setVisible={setVisible}
      />
      <DeleteCostProfileModal
        costProfile={costProfileToDelete}
        visible={deleteVisible}
        setVisible={setDeleteVisible}
      />

      <div className={styles.buttonContainer}>
        <CustomButton
          onClick={() => {
            setCostProfile(structuredClone(CostProfileSample));
            setIsCreate(true);
            setVisible(true);
          }}
          css={{ width: "100%"}}
        >
          <Text b css={{ color: "#6c757e",  }}>
            Add Cost Profile
          </Text>
        </CustomButton>
      </div>
      <div className={styles.tableContainer}>
        <Table
          aria-label="Cost Profile Table"
          css={{
            height: "auto",
            minWidth: "500px",
          }}
          shadow={false}
          selectionMode="none"
          hoverable={false}
        >
          <Table.Header columns={columns}>
            {(column) => (
              <Table.Column
                allowsSorting
                key={column.uid}
                hideHeader={column.uid === "actions"}
                align={column.uid === "actions" ? "center" : "start"}
              >
                {column.name}
              </Table.Column>
            )}
          </Table.Header>
          <Table.Body items={costProfiles}>
            {(item) => (
              <Table.Row css={{
                color: '#4f5153',
              }}>
                {(columnKey) => (
                  <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                )}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}
