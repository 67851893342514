import { FunctionComponent, useState } from "react";

import {
  Activity,
} from "@controllers";
import HeadBar from "@components/headBar";
import JulePerformance, { SingleJuleProps } from "./components/julePerformance";
import styles from "./chargingDispensers.module.scss";
import { useTranslation } from "react-i18next";
import { GroupFieldValues, SECCs, useFieldContext } from "@services";
import { useMetrics } from "@ecamion/sucrose";
import { useSelector } from "react-redux";
import { getDateWithFixedHours, getUnixTimestamp } from "@utils/common";
import configData from "@config/config";

const ChargingDispensers: FunctionComponent = () => {
  const { t: translation } = useTranslation();
  const headBarTitle = "EV Charging Dispensers";
  const systemStates = useSelector((state: any) => {
    return {
      id: state.system.id,
    };
  });
  const [chargers, setChargers] = useState(0);
  const [dailyChargingData, setDailyChargingData] = useState({
    num_sessions: 0,
    total_energy_dispensed: 0,
    max_power: 0,
    avg_power: 0,
    total_faults: 0,
    pedestals : {}
  });

  const startDate = getUnixTimestamp(
    getDateWithFixedHours(configData.meteringConfig.endDate, true)
  );
    
  /**
   * useMetrics for getting the daily charging performance
   */
  useMetrics({
    fields: [
      {
        group: "public.session",
        system: systemStates.id,
        start: startDate
 
      },
      // Failed sessions
      {
        group: "SECC-1",
        field: "StatsFailedSessions",
        system: systemStates.id,
        aggregate: "max",
        limit: 1,
      },
      {
        group: "SECC-2",
        field: "StatsFailedSessions",
        system: systemStates.id,
        aggregate: "max",
        limit: 1,
      },
      {
        group: "SECC-3",
        field: "StatsFailedSessions",
        system: systemStates.id,
        aggregate: "max",
        limit: 1,
      },
      {
        group: "SECC-4",
        field: "StatsFailedSessions",
        system: systemStates.id,
        aggregate: "max",
        limit: 1,
      },

      /** average power delivered */
      {
        group: "public.session",
        field: "avepowerdeli",
        system: systemStates.id,
        start: startDate,
        precision: "1day",
        aggregate: "avg",
      },
      /** max power delivered */
      {
        group: "public.session",
        field: "maxpowerdeli",
        system: systemStates.id,
        start: startDate,
        precision: "1day",
        aggregate: "max",
      },
      // count the ids, for the vehicles charged value
      {
        group: "public.session",
        field: "id",
        system: systemStates.id,
        start: startDate,
        precision: "1day",
        aggregate: "count",
      },
      // total energy delivered
      {
        group: "public.session",
        field: "totenergydeli",
        system: systemStates.id,
        start: startDate,
        precision: "1day",
        aggregate: "sum",
      },
    ],
    transformer: (data: any) => {
      let result = {
        num_sessions: 0,
        total_energy_dispensed: 0,
        max_power: 0,
        avg_power: 0,
        total_faults: 0,
        pedestals: {} as any,
      };
      /**
       * getting the avg_power, num_sessions, max_power and total_energy_dispensed values
       */
      Object.entries(data[systemStates.id]?.["public.session"] ?? {}).forEach(([label, objectValue]:  (number | any)[], i: number) => {
        const valuesArray : (number | any)[] = Object.values(objectValue).flat(3);
        switch (label) {
          case "Avepowerdeli.1day.avg.":
            result.avg_power = valuesArray.length > 0 ? parseFloat(valuesArray[0].toFixed(2)) : 0;
            break;
          case "Id.1day.count.":
            result.num_sessions = valuesArray.length > 0 ? parseFloat(valuesArray[0].toFixed(2)) : 0;
            break;
          case "Maxpowerdeli.1day.max.":
            result.max_power = valuesArray.length > 0 ? parseFloat(valuesArray[0].toFixed(2)) : 0;
            break;
          case "Totenergydeli.1day.sum.":
            result.total_energy_dispensed = valuesArray.length>0 ? parseFloat(valuesArray[0].toFixed(2)) : 0;
            break;
          default:
            valuesArray.forEach((session)=>{
              if(!result.pedestals[session.charger_id]){
                result.pedestals[session.charger_id] = {
                  "num_sessions" : 0,
                  "total_energy_dispensed": 0
                }
              }
              result.pedestals[session.charger_id]["num_sessions"]++;
              result.pedestals[session.charger_id]["total_energy_dispensed"] += session.totenergydeli
            })
            break;
        }
      });
      
      delete data[systemStates.id]?.["public.session"];
      /**
       * getting the total_faults value from all SECC
       */
      Object.values(data[systemStates.id] ?? {}).forEach((stats: any, index: any) => {
        // faults accum
        const faults: any[] = Object.values(stats["StatsFailedSessions..max."] ?? {}).flat(3);
        result.total_faults += faults.length > 0 ? faults[0] : 0;
      });
      setDailyChargingData(result);
    },
    refetchInterval:10 * 1000,
  });

 

  const parseJuleStats = (
    pedestals: { [key: number]: any },
    fields: GroupFieldValues
  ) => {
    const julePerf: { [key: number]: SingleJuleProps } = {};
  
    Array(4)
      .fill(0)
      .forEach((_, i) => {
        const secc = fields[`SECC-${i + 1}` as keyof SECCs];
        if (secc) {
          const s = pedestals[i + 1] || {
            total_energy_dispensed: 0,
          };
          const j: SingleJuleProps = {
            status: secc.SeccStatus || 0,
            power: secc.MeterPresentPower || 0,
            ...s,
          };
          j.total_energy_dispensed = parseFloat(j.total_energy_dispensed.toFixed(2))
          j.charger_id = i + 1;
          julePerf[i + 1] = j;
        }
      });
      if(chargers === 0) setChargers(Object.keys(julePerf).length);
    return julePerf;
  }

  const fields = useFieldContext();
  const juleStats = parseJuleStats(dailyChargingData.pedestals, fields);

  return (
    <div className={styles.root_container}>
      <HeadBar title={headBarTitle} />
      <div className={styles.main_container}>
        <div className={styles.main_left_container}>
          <p
            className={[styles.dispenserTitle, styles.daily_charging].join(" ")}
          >
            {translation("chargingDispenser.dailyChargingPerformance")}
          </p>

          <p className={[styles.capacity, styles.dispenserTitle].join(" ")}>
            Total Chargers: {chargers}
          </p>

          <div className={styles.dispenser_info}>
            <div className={styles.dispenser_row}>
              <p className={styles.dispenser_text}>
                Total Number of Vehicles Charged:
              </p>
              {
                <div className={styles.dispenser_indicator}>
                  {dailyChargingData.num_sessions}
                </div>
              }
            </div>
            <div className={styles.dispenser_row}>
              <p className={styles.dispenser_text}>
                Total Energy Delivered(kWh):
              </p>
              <p className={styles.dispenser_indicator}>
                {dailyChargingData.total_energy_dispensed}
              </p>
            </div>
            <div className={styles.dispenser_row}>
              <div className={styles.dispenser_text}>
                Maximum Power Level Achieved(kW):
              </div>
              {
                <div className={styles.dispenser_indicator}>
                  {dailyChargingData.max_power}
                </div>
              }
            </div>
            <div className={styles.dispenser_row}>
              <div className={styles.dispenser_text}>
                Average Power Level(kW):
              </div>
              {
                <div className={styles.dispenser_indicator}>
                  {dailyChargingData.avg_power}
                </div>
              }
            </div>

            <div className={styles.dispenser_row}>
              <div className={styles.dispenser_text}>
                Total Charging Faults:
              </div>
              <div className={styles.dispenser_indicator}>
                {dailyChargingData.total_faults}
              </div>
            </div>
          </div>

          <div className={styles.link_container}>
            <div
              className={styles.link_item}
              onClick={() => {
                const win: any = window.open(
                  "http://99.79.62.196:8080/steve/manager/home",
                  "_blank"
                );
                win.focus();
              }}
            >
              OCPP
            </div>

            <div
              className={styles.link_item}
              onClick={() => {
                const win: any = window.open("https://my.nayax.com/", "_blank");
                win.focus();
              }}
            >
              Nayax Payment
            </div>
          </div>
        </div>

        <div className={styles.main_mid_container}>
          <JulePerformance juleStats={juleStats} />
        </div>
        <div className={styles.main_right_container}>
          <Activity />
        </div>
      </div>
    </div>
  );
};



export default ChargingDispensers;


