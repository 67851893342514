import "react-circular-progressbar/dist/styles.css";

import { FunctionComponent } from "react";
import {
  systemStatusColorDecoder,
  systemStatusValueDecoder,
  systemStatusChargerMap,
} from "@utils/systemStatus";

import HeadBar from "@components/headBar";
import ProgressCircle from "./components/progressCircle";
import TempMeter from "./components/tempMeter";
import styles from "./systemStatus.module.scss";
import { useTranslation } from "react-i18next";
import { ESS } from "@services/sse/types";
import { useFieldContext } from "@services/index";

const SystemStatus: FunctionComponent = () => {
  const { t: translation } = useTranslation();
  const fields = useFieldContext();
  const { ESS, PCCabinet, MeterSummary } = fields;

  const essPowerColor = () => {
    if ((ESS?.SysPower || 0) >= 0) {
      //charging
      if ((ESS?.SysPower || 0) / 200 === 1) {
        return "#c60202"; //red
      } else if ((ESS?.SysPower || 0) / 200 > 0.9) {
        return "#ea9c00"; //yellow
      } else {
        return "#378735"; //green
      }
    } else {
      //discharging
      if (Math.abs(ESS?.SysPower || 0) / 600 === 1) {
        return "#c60202"; //red
      } else if (Math.abs(ESS?.SysPower || 0) / 600 > 0.9) {
        return "#ea9c00"; //yellow
      } else {
        return "#378735"; //green
      }
    }
  };

  const essSOCColor = () => {
    switch (true) {
      case (ESS?.SysSoc || 0) <= 10:
        return "#c60202"; //red
      case (ESS?.SysSoc || 0) <= 20:
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const essVoltageColor = () => {
    switch (true) {
      case (ESS?.SysVoltage || 0) >= (ESS?.MaxVoltageLimit || 1):
        return "#c60202"; //red
      case (ESS?.SysVoltage || 0) >= (ESS?.MaxVoltageWarn || 1):
        return "#ea9c00"; //yellow
      case (ESS?.SysVoltage || 0) <= (ESS?.MinVoltageLimit || -1):
        return "#c60202"; //red
      case (ESS?.SysVoltage || 0) <= (ESS?.MinVoltageWarn || -1):
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const realPowerColor = () => {
    switch (true) {
      case (MeterSummary?.ChargehubWPower || 0) / 1000 >= 190:
        return "#c60202"; //red
      case (MeterSummary?.ChargehubWPower || 0) / 1000 >= 175:
        return "#ea9c00"; //yellow
      case (MeterSummary?.ChargehubWPower || 0) / 1000 <= -190:
        return "#c60202"; //red
      case (MeterSummary?.ChargehubWPower || 0) / 1000 <= -175:
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const reactivePowerColor = () => {
    switch (true) {
      case (MeterSummary?.ChargehubVaPower || 0) / 1000 >= 190:
        return "#c60202"; //red
      case (MeterSummary?.ChargehubVaPower || 0) / 1000 >= 175:
        return "#ea9c00"; //yellow
      case (MeterSummary?.ChargehubVaPower || 0) / 1000 <= -190:
        return "#c60202"; //red
      case (MeterSummary?.ChargehubVaPower || 0) / 1000 <= -175:
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const cellTempColor = (sensorType: any) => {
    return "gray"; // for now, the color is fixed to the default text color (gray) for all values
    switch (true) {
      case sensorType >= (ESS?.MaxCellTempLimit || 0):
        return "orange"; //red
      case sensorType >= (ESS?.MaxCellTempWarn || 0):
        return "yellow"; //yellow
      case sensorType <= (ESS?.MinCellTempLimit || 0):
        return "orange"; //red
      case sensorType <= (ESS?.MinCellTempWarn || 0):
        return "yellow"; //yellow
      default:
        return "gray"; //green
    }
  };

  const essTempColor = () => {
    return "gray"; // for now, the color is fixed to the default text color (gray) for all values
    switch (true) {
      case ESS?.SysTemp || 0 >= (ESS?.MaxSysTempLimit || 1):
        return "orange"; //red
      case ESS?.SysTemp || 0 >= (ESS?.MaxSysTempWarn || 1):
        return "yellow"; //yellow
      case ESS?.SysTemp || 0 <= (ESS?.MinSysTempLimit || -1):
        return "orange"; //red
      case ESS?.SysTemp || 0 <= (ESS?.MinSysTempWarn || -1):
        return "yellow"; //yellow
      default:
        return "gray"; //green
    }
  };
  const pcTempColor = () => {
    return "gray"; // for now, the color is fixed to the default text color (gray) for all values
    switch (true) {
      case PCCabinet?.PCTemp || 0 >= (ESS?.MaxSysTempLimit || 1):
        return "orange"; //red
      case PCCabinet?.PCTemp || 0 >= (ESS?.MaxSysTempWarn || 1):
        return "yellow"; //yellow
      case PCCabinet?.PCTemp || 0 <= (ESS?.MinSysTempLimit || -1):
        return "orange"; //red
      case PCCabinet?.PCTemp || 0 <= (ESS?.MinSysTempWarn || -1):
        return "yellow"; //yellow
      default:
        return "gray"; //green
    }
  };

  const stringOn = (() => {
    if (!ESS) return "--";
    for (let i = 0; i < 3; i++) {
      const status = ESS[`String${i + 1}Status` as keyof ESS];
      if (status !== 4) return "OFF";
    }
    return "ON";
  })();

  const { heater, aircon } = (() => {
    if (ESS?.SysHvacStatus === undefined) return { heater: "--", aircon: "--" };
    const aircon = [1, 4].includes(ESS.SysHvacStatus) ? "ON" : "OFF";
    const heater = [2, 5].includes(ESS.SysHvacStatus) ? "ON" : "OFF";
    return { aircon, heater };
  })();

  return (
    <div className={styles.main_container}>
      <HeadBar title="System Status" />
      <div className={styles.main_container2}>
        <div className={styles.diagnostic_SE_BS_con}>
          <div className={styles.diagnostic_SE_con}>
            <div className={styles.diagnostic_SE_upper_con}>
              <div className={styles.diagnostic_SE_upper_con_A}>
                <div className={styles.diagnostic_SE_upper_A_title}>
                  {translation("systemStatus.systemEnvironment")}
                </div>
                <div className={styles.diagnostic_SE_upper_A_status_row}>
                  <div className={styles.diagnostic_SE_upper_A_status_bar1} />
                  <div className={styles.diagnostic_SE_upper_A_status_text}>
                    {translation("systemStatus.warning")}
                  </div>
                  <div className={styles.diagnostic_SE_upper_A_status_bar2} />
                  <div className={styles.diagnostic_SE_upper_A_status_text}>
                    {translation("systemStatus.urgent")}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_A_title3}>
                  {systemStatusChargerMap(ESS?.ESSState || 0)}
                </div>

                <div className={styles.diagnostic_SE_con1}>
                  <div className={styles.diagnostic_SE_con1_row}>
                    <div className={styles.diagnostic_SE_con1_param}>
                      {translation("systemStatus.chargeLimit")}
                    </div>
                    <div className={styles.diagnostic_SE_con1_value}>
                      {ESS?.ChargeCurrentLimit || 0} A
                    </div>
                  </div>
                  <div className={styles.diagnostic_SE_con1_row}>
                    <div className={styles.diagnostic_SE_con1_param}>
                      {translation("systemStatus.dischargeLimit")}
                    </div>
                    <div className={styles.diagnostic_SE_con1_value}>
                      {Math.abs(ESS?.DischargeCurrentLimit || 0)} A
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.diagnostic_SE_upper_con_B}>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.batteryStringOperation")}
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {stringOn}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    Battery Isolation Monitoring
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {/* Note: this is a placeholder for the battery isolation monitoring status */}
                    {"ON"}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    Heater
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {heater}
                  </div>
                </div>
              </div>

              <div className={styles.diagnostic_SE_upper_con_B}>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.airConditioner")}
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {aircon}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.waterSensor")}
                  </div>
                  <div
                    className={styles.diagnostic_SE_upper_row_indicator}
                    style={{
                      color: `${systemStatusColorDecoder(
                        ESS?.SysWaterStatus || 0
                      )}`,
                    }}
                  >
                    {ESS?.SysWaterStatus !== undefined
                      ? systemStatusValueDecoder(ESS.SysWaterStatus)
                      : "--"}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.smokeDetector")}
                  </div>
                  <div
                    className={styles.diagnostic_SE_upper_row_indicator}
                    style={{
                      color: `${systemStatusColorDecoder(
                        ESS?.SysSmokeStatus || 0
                      )}`,
                    }}
                  >
                    {ESS?.SysSmokeStatus !== undefined
                      ? systemStatusValueDecoder(ESS.SysSmokeStatus)
                      : "--"}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.fireExtinguisher")}
                  </div>
                  <div
                    className={styles.diagnostic_SE_upper_row_indicator}
                    style={{
                      color: `${systemStatusColorDecoder(
                        ESS?.SysExtinguisherStatus || 0
                      )}`,
                    }}
                  >
                    {ESS?.SysExtinguisherStatus !== undefined
                      ? systemStatusValueDecoder(ESS?.SysExtinguisherStatus)
                      : "--"}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.diagnostic_SE_lower_con}>
              <div className={styles.diagnostic_SE_lower_progressbar}>
                <div className={styles.voltage_bottomline}>
                  {(ESS?.SysPower || 0) >= 0
                    ? translation("systemStatus.rateOfCharge")
                    : translation("systemStatus.rateOfDischarge")}
                </div>
                <div className={styles.prog_circle}>
                  <ProgressCircle
                    barValue={
                      (ESS?.SysPower || 0) >= 0
                        ? ((ESS?.SysPower || 0) / 200) * 100
                        : Math.abs((ESS?.SysPower || 0) / 600) * 100
                    }
                    barColor={essPowerColor()}
                    numValue={ESS?.SysPower || 0}
                    unit="kW"
                  />
                </div>
              </div>
              <div className={styles.diagnostic_SE_lower_progressbar}>
                <div className={styles.voltage_bottomline}>
                  {translation("systemStatus.stateOrCharge")} <br /> (
                  {(((ESS?.SysSoc || 0) / 100) * 486).toFixed(0)} kWh)
                </div>
                <div className={styles.prog_circle}>
                  <ProgressCircle
                    barValue={ESS?.SysSoc || 0}
                    barColor={essSOCColor()}
                    numValue={ESS?.SysSoc || 0}
                    unit="%"
                  />
                </div>
              </div>
              <div className={styles.diagnostic_SE_lower_progressbar}>
                <div className={styles.voltage_bottomline}>
                  {translation("systemStatus.relativeHumidity")}
                </div>
                <div className={styles.prog_circle}>
                  <ProgressCircle
                    barValue={ESS?.SysHumidity || 0}
                    barColor="#378735" //placeholder
                    numValue={ESS?.SysHumidity || 0}
                    unit="%"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.diagnostic_BS_con}>
            <div className={styles.bottom_container_header}>
              {translation("systemStatus.temperatureStatus")}
            </div>
            <div className={styles.diagnostic_BS_temp_con}>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={translation("systemStatus.minCellTemperature")}
                  color={cellTempColor(ESS?.CellMinTemp || 0)}
                  data={ESS?.CellMinTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={"Max. Cell Temperature"}
                  color={cellTempColor(ESS?.CellMaxTemp || 0)}
                  data={ESS?.CellMaxTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={translation("systemStatus.avgCellTemperature")}
                  color={cellTempColor(ESS?.CellAvgTemp || 0)}
                  data={ESS?.CellAvgTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={translation(
                    "systemStatus.batteryEnclosureTemperature"
                  )}
                  color={essTempColor()}
                  data={ESS?.SysTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={"Power Converter Enclosure Temperature"}
                  color={pcTempColor()}
                  data={PCCabinet?.PCTemp || 0}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mid_container}>
          <div className={styles.mid_container_left_header}>
            {translation("systemStatus.electricalStatus")}
          </div>

          <div className={styles.mid_container_left_progressbarlist}>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.systemVoltage")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    (ESS?.SysVoltage || 0 / (ESS?.MaxVoltageLimit || 1)) * 100
                  }
                  barColor={essVoltageColor()}
                  numValue={ESS?.SysVoltage || 0}
                  unit="V"
                />
              </div>
            </div>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.chargehubRealPower")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    (Math.abs(MeterSummary?.ChargehubWPower || 0) /
                      (200 * 1000)) *
                    100
                  }
                  barColor={realPowerColor()}
                  numValue={Number(
                    ((MeterSummary?.ChargehubWPower || 0) / 1000).toFixed(2)
                  )}
                  unit="kW"
                />
              </div>
            </div>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.chargehubReactivePower")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    (Math.abs(MeterSummary?.ChargehubVarPower || 0) /
                      (200 * 1000)) *
                    100
                  }
                  barColor={reactivePowerColor()}
                  numValue={Number(
                    ((MeterSummary?.ChargehubVarPower || 0) / 1000).toFixed(2)
                  )}
                  unit="kVar"
                />
              </div>
            </div>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.powerFactor")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    Math.abs(MeterSummary?.ChargehubPowerFactor || 0) * 100
                  }
                  barColor="#378735" //placeholder
                  numValue={Number(
                    (MeterSummary?.ChargehubPowerFactor || 0).toFixed(2)
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemStatus;
