import React, { memo, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { G2, Heatmap } from '@ant-design/plots'
import { AlertCleared, round, transformToAntdStandard,FieldSpec, useDeviceConnectionStatus, useMetrics, useSECCAlerts, MetricsDef, useCustomMetrics, useFilteredSystems, useTimeRange, createRecoil, useSystems } from '@ecamion/sucrose'
import { getDateWithFixedHours, getUnixTimestamp } from '@utils/common'
import dayjs from 'dayjs';
import { useModal } from '@nextui-org/react'


interface OutageRecord {
    start: number
    end: number
    reason: OutageReason
    systemId: number
    seccId?: number
}

enum OutageReason {
    SystemOffline = 'System Offline',
    ModbusDisconnected = 'Modbus Disconnected',
    SECCBit1Set = 'EVSE Fault',
}

const useTotalUptime = createRecoil<{ [key: string]: number }>("volatile", "system-total-avg-uptime", {})

const UptimeCalendarChart = ({
    inclufdeOffline, systemId,   asSECCId,start, end
}: {
    inclufdeOffline: boolean,
    systemId: number,
    asSECCId: number,
    start: Date,
    end: Date
}) => {

    const startDate = getUnixTimestamp(getDateWithFixedHours(start,true));
    const endDate = getUnixTimestamp(getDateWithFixedHours(end));
    const [filteredSystems] = useFilteredSystems()
    const systems =  filteredSystems

    const [totalUptime, setTotalUptime] = useTotalUptime()

    const filteredSystemsAvgUptime = useMemo<number>(() => {
        let success = true
        const filteredUptime = filteredSystems.reduce((acc, system) => {
            if (totalUptime[system.id] === undefined) {
                success = false
                return acc
            } else if (isNaN(totalUptime[system.id])) {
                return acc
            } else {
                return acc + totalUptime[system.id]
            }
        }, 0)
        if (!success) {
            return NaN
        }
        return round(filteredUptime / filteredSystems.length, 3)
    }, [totalUptime, filteredSystems])

    const {raw: seccAlerts} = useSECCAlerts({
        start: startDate,
        refetchInterval: false,
        end: endDate,
    })
    const fields: FieldSpec[] = useMemo(() => {
        return systems.map((system) => {
            return {
                group: "SystemStatus",
                field: "Connected",
                system: system.id,
                start: startDate,
                end: endDate,
            }
        })
    }, [systems,startDate, endDate])
    const [d] = useMetrics<MetricsDef[]>(
        {
            fields: fields,
            transformer: transformToAntdStandard,
            refetchInterval: false,
        }
    )
    const [modbus] = useCustomMetrics({
        payload: {
            systems: systems.map(s => s.id).join(","),
            start: startDate,
            end: endDate,
        },
        path: "modbus/connection",
        refetchInterval: false,
    })
    const systemOfflineTime = useMemo(() => {
        const offline: any[] = []
        const lastOffline: any = {}
        d?.forEach((row) => {
            const systemId = row.field.split(".")?.[0]
            if (systemId) {
                if (row.value === "false") {
                    lastOffline[systemId] = row.unix
                } else {
                    if (lastOffline[systemId]) {
                        offline.push({
                            systemId: systemId,
                            start: lastOffline[systemId],
                            end: row.unix
                        })
                        delete lastOffline[systemId]
                    }
                }
            }
        })
        return offline.filter((o) => {
            return o.end - o.start > 60
        })
    }, [d])


    const modbusOfflineTime = useMemo(() => {
        const offline: any[] = []
        const lastOffline: any = {}
        modbus?.data?.filter((s: any) => {
            return s.group_name === "SECC"
        }).sort((a: any, b: any) => {
            return dayjs(a.timestamp).unix() - dayjs(b.timestamp).unix()
        }).forEach((row: any) => {
            const systemId = row.system_id
            if (systemId) {
                const identifier = `${systemId}.${row.group_name}.${row.name}`
                if (row.connected === false) {
                    lastOffline[identifier] = dayjs(row.timestamp).unix()
                } else if (lastOffline[identifier] && dayjs(row.timestamp).unix() > lastOffline[identifier]) {
                    offline.push({
                        systemId: systemId,
                        start: lastOffline[identifier],
                        end: dayjs(row.timestamp).unix(),
                        groupName: row.group_name,
                        name: row.name,
                    })
                    delete lastOffline[identifier]
                }
            }
        })
        return offline.filter((o) => {
            return o.end - o.start > 60
        })
    }, [modbus.data])


    const records: { [key: number]: OutageRecord[] } = useMemo(() => {
        const records: { [key: number]: OutageRecord[] } = {}
        const end = endDate ?? dayjs().unix()
        if (inclufdeOffline) {
            systemOfflineTime.forEach((o) => {
                const systemId = o.systemId
                if (!records[systemId]) {
                    records[systemId] = []
                }
                records[systemId].push({
                    start: o.start,
                    end: Math.min(o.end, end),
                    reason: OutageReason.SystemOffline,
                    systemId: parseInt(systemId),
                })
            })
        }
        modbusOfflineTime.forEach((o) => {
            const systemId = o.systemId
            const group = o.groupName
            if (group === "SECC") {
                if (!records[systemId]) {
                    records[systemId] = []
                }
                records[systemId].push({
                    start: o.start,
                    end: Math.min(o.end, end),
                    reason: OutageReason.ModbusDisconnected,
                    systemId: systemId,
                    seccId: parseInt(o.name),
                })
            }
        })
        seccAlerts.forEach((a: AlertCleared) => {
            const systemId = a.SystemId
            const seccId = a.GroupName.split("-")?.[1]
            if (seccId && a.AlarmId === 12) {
                if (!records[systemId]) {
                    records[systemId] = []
                }
                records[systemId].push({
                    start: a.Time,
                    end: Math.min(a.EndTime, end),
                    reason: OutageReason.SECCBit1Set,
                    systemId: systemId,
                    seccId: parseInt(seccId),
                })
            }
        })
        Object.keys(records).forEach((systemId: any) => {
            records[systemId].sort((a, b) => a.start - b.start)
        })
        return records
    }, [systemOfflineTime, modbusOfflineTime, seccAlerts, inclufdeOffline])

    return (
        <SystemCalendar
        asSECCId={asSECCId}
        key={systemId}
        systemId={systemId}
        outages={records[systemId] ?? []}
        startDate={startDate}
        endDate={endDate}
    />
    )
}
function useTotalSECCs(systemId: number) {
    const {all} = useDeviceConnectionStatus(systemId)
    return useMemo(() => {
        const seccs = all.filter((d) => d.name.includes("SECC-"))
        return seccs.map((d) => parseInt(d.name.split("-")?.[1]))
    }, [all])
}


function getCalendarConfig(startDate : number, endDate: number, uptimePercentByDate: { [key: string]: number },
                           outages: OutageRecord[], seccId?: number) {
    G2.registerShape('polygon', 'boundary-polygon', {
        draw(cfg: any, container: any) {
            const group = container.addGroup();
            const attrs = {
                stroke: '#fff',
                lineWidth: 1,
                fill: cfg.color,
                paht: [],
            };
            const points = cfg.points;
            const path = [
                ['M', points[0].x, points[0].y],
                ['L', points[1].x, points[1].y],
                ['L', points[2].x, points[2].y],
                ['L', points[3].x, points[3].y],
                ['Z'],
            ]; // @ts-ignore

            attrs.path = this.parsePath(path);
            group.addShape('path', {
                attrs,
            });

            return group;
        },
    });
    // for every day in start-end range, create a record for each day
    const startD = dayjs.unix(startDate!)
    const endD = dayjs.unix(endDate ?? dayjs().unix())
    const days = endD.diff(startD, "day")
    const records: any[] = []
    for (let i = 0; i <= days; i++) {
        const day = startD.add(i, "day")
        records.push({
            date: day.format("YYYY-MM-DD"),
            value: 0,
            day: day.day(),
            month: day.month(),
            unix: day.unix()
        })
    }
    // for every day, find the record that matches the day and set the value to that day's uptime percent
    records.forEach((r) => {
        const day = dayjs(r.date)
        const uptime = uptimePercentByDate[day.format("YYYY-MM-DD")]
        if (uptime !== undefined) {
            r.value = uptime
        }
    })


    // transform the records into a format that the calendar can use
    let transformed: any[] = []
    records.forEach((r) => {
        const day = dayjs(r.date).format("ddd")
        transformed.push({
            ...r,
            day,
        })
    })
    transformed.sort((a: any, b: any) => a.unix - b.unix)
    const start = dayjs(transformed[0]?.date).add(-1, "day")
    if (transformed.length > 0) {
        transformed = transformed.map((d: any) => {
            const djs = dayjs(d.date)
            return {
                ...d,
                week: `${djs.diff(start, "week")}`,
                uptime: d.value
            }
        })

        transformed.forEach((d: any) => {
            const reasons: Set<string> = new Set()
            // iterate over outages and find the matching one with same seccId or seccId undefined

            outages.forEach((record: OutageRecord) => {
                // if(dayjs.unix(d.unix).format("YYYY-MM-DD")==="2022-12-30"){

                //   console.log(dayjs.unix(d.unix).format("YYYY-MM-DD"), (record.seccId === seccId || seccId === undefined || record.seccId === undefined),
                //   record.start <= d.unix, record.end >= d.unix, dayjs.unix(record.start).format("MM-DD HH:mm"), dayjs.unix(record.end).format("MM-DD HH:mm"), record.reason)
                // }
                if ((record.seccId === seccId || seccId === undefined || record.seccId === undefined) && (record.start <= d.unix || record.start <= d.unix + 86400) && record.end >= d.unix) {
                    // set start to the start of the day if the outage starts before the day
                    const start = record.start < d.unix ? d.unix : record.start
                    // set end to the end of the day if the outage ends after the day
                    const end = record.end > d.unix + 86400 ? d.unix + 86400 : record.end
                    reasons.add(`${(seccId === undefined && record.seccId !== undefined) ? `JULE-${record.seccId}:` : ""} ${dayjs.unix(start).format("HH:mm")} - ${dayjs.unix(end).format("HH:mm")}: ${record.reason}`)
                }
            })
            d.cause = Array.from(reasons).join("\n")
        })
        transformed.pop()
    }
    return {
        data: transformed,
        autoFit: true,
        xField: 'week',
        yField: 'day',
        colorField: 'value',
        reflect: 'y',
        shape: 'boundary-polygon',
        meta: {
            day: {
                type: 'cat',
                values: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            },
            week: {
                type: 'cat',
            },
            value: {
                sync: true,
            },
            date: {
                type: 'cat',
            },
            label: {
                formatter: (val: any) => {
                    return val === 1 ? 'Outage' : ''
                }
            }
        },
        yAxis: {
            grid: null,
        },
        tooltip: {
            title: 'date',
            showMarkers: false,
            fields: ["uptime", "cause"],
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
        animation: false,
        xAxis: {
            position: 'top',
            tickLine: null,
            line: null,
            label: {
                offset: 12,
                style: {
                    fontSize: 12,
                    fill: '#666',
                    textBaseline: 'top',
                },
                formatter: (val: any) => {
                    return start.add(val, 'week').format('MMM Do')
                },
            },
        },
    };
}

export const SystemCalendar = memo(({
    startDate, endDate, systemId, outages,  asSECCId
                                    }: {
    startDate : number, 
    endDate: number,
    systemId: number,
    outages: OutageRecord[],
    asSECCId: number
}) => {

    const seccs = useTotalSECCs(systemId)
    console.log("seccs", seccs)
    console.log("outages", outages)
    console.log("asSECCId", asSECCId)
    console.log("systemId", systemId)
    const mergedOfflineRangePerSECC = useMemo(() => {
        const merged: { [key: number]: { start: number, end: number }[] } = {}
        outages.forEach((o) => {
            if (o.seccId) {
                if (!merged[o.seccId]) {
                    merged[o.seccId] = []
                }
                const last = merged[o.seccId][merged[o.seccId].length - 1]
                if (last && last.end >= o.start) {
                    last.end = Math.max(last.end, o.end)
                } else {
                    merged[o.seccId].push({
                        start: o.start,
                        end: o.end,
                    })
                }
            } else {
                seccs.forEach((seccId) => {
                    if (!merged[seccId]) {
                        merged[seccId] = []
                    }
                    const last = merged[seccId][merged[seccId].length - 1]
                    if (last && last.end >= o.start) {
                        last.end = Math.max(last.end, o.end)
                    } else {
                        merged[seccId].push({
                            start: o.start,
                            end: o.end,
                        })
                    }
                })
            }
        })
        return merged
    }, [outages])


    const uptimePercentByDateBySECC = useMemo(() => {
        const uptimeByDateBySECC: { [key: number]: { [key: string]: number } } = {}
        const total = 24 * 60 * 60
        const start = startDate!
        const end = endDate ?? dayjs().unix()
        const days = Math.ceil((end - start) / (24 * 60 * 60))
        for (let i = 0; i < days; i++) {
            const dayStart = start + i * 24 * 60 * 60
            const dayEnd = Math.min(dayStart + 24 * 60 * 60, end)
            seccs.forEach((seccId) => {
                if (!uptimeByDateBySECC[seccId]) {
                    uptimeByDateBySECC[seccId] = {}
                }
                const offline = mergedOfflineRangePerSECC[seccId]?.reduce((acc, o) => {
                    return acc + Math.max(0, Math.min(o.end, dayEnd) - Math.max(o.start, dayStart))
                }, 0) ?? 0
                uptimeByDateBySECC[seccId][dayjs.unix(dayStart).format("YYYY-MM-DD")] = round((total - offline) / total * 100, 3)
            })
        }
        return uptimeByDateBySECC
    }, [mergedOfflineRangePerSECC, seccs, startDate,endDate])
    console.log(uptimePercentByDateBySECC,mergedOfflineRangePerSECC)
    // computer uptimePercentByDate by multiplying all SECCs
    const uptimePercentByDate = useMemo(() => {
        const uptimeByDate: { [key: string]: number } = {}
        const total = 24 * 60 * 60
        const start = startDate!
        const end = endDate ?? dayjs().unix()
        const days = Math.ceil((end - start) / (24 * 60 * 60))
        for (let i = 0; i < days; i++) {
            const totalPercentage = seccs.length * 100
            const dayStart = start + i * 24 * 60 * 60
            const dayEnd = Math.min(dayStart + 24 * 60 * 60, end)
            const uptimeBySECC = seccs.map((seccId) => {
                const offline = mergedOfflineRangePerSECC[seccId]?.reduce((acc, o) => {
                    return acc + Math.max(0, Math.min(o.end, dayEnd) - Math.max(o.start, dayStart))
                }, 0) ?? 0
                return round((total - offline) / total * 100, 3)
            })
            const uptime = uptimeBySECC.reduce((acc, u) => acc + u, 0)
            uptimeByDate[dayjs.unix(dayStart).format("YYYY-MM-DD")] = round(uptime / totalPercentage * 100, 3)

        }
        return uptimeByDate
    }, [uptimePercentByDateBySECC, startDate,endDate])
    console.log(uptimePercentByDate)
    // calculate the average uptime percentage from uptimePercentByDate
    const averageUptime = useMemo(() => {
        const uptime = Object.values(uptimePercentByDate).reduce((acc, u) => acc + u, 0)
        return round(uptime / Object.keys(uptimePercentByDate).length, 3)
    }, [uptimePercentByDate])
    console.log(averageUptime)
    const [totalUptime, setTotalUptime, updateTotalUptime] = useTotalUptime()
    useEffect(() => {
        setTotalUptime(prev => {
            const s = { ...prev }
            s[systemId] = averageUptime
            return s
        })
    }, [averageUptime, systemId, setTotalUptime])
    console.log(totalUptime)
    const config: any = useMemo(() => {
        return getCalendarConfig(startDate,endDate, uptimePercentByDate, outages)
    }, [uptimePercentByDate, startDate,endDate]);

    
    const seccConfigs = useMemo(() => {
        const cs: { [key: number]: any } = {}
        seccs.forEach((seccId) => {
            let averageUptime = 0
            if (uptimePercentByDateBySECC[seccId]) {
                const uptime = Object.values(uptimePercentByDateBySECC[seccId]).reduce((acc, u) => acc + u, 0)
                averageUptime = round(uptime / Object.keys(uptimePercentByDateBySECC[seccId]).length, 3)
                cs[seccId] = [getCalendarConfig(startDate,endDate, uptimePercentByDateBySECC[seccId], outages, seccId), averageUptime]
            }
        })
        return cs
    }, [uptimePercentByDateBySECC, startDate,endDate]);
    console.log(config)
    console.log(seccConfigs[asSECCId])
    const title = `Jule ${asSECCId} ${ ((asSECCId !== undefined && seccConfigs[asSECCId]  && seccConfigs[asSECCId][1]) ? `${seccConfigs[asSECCId][1]}%` : `${averageUptime}%`)} `;
    return (
            <div style={{ width: "100%", display: "flex", flexDirection: "column"}}>
                <div style={{width: "100%",  display: "flex", marginTop: "20px", paddingLeft: "20px", alignItems: "center", justifyContent: "start" }}>
                    <h4>{title}</h4>

                </div>
                <div style={{width: "100%",  display: "flex", alignItems: "center", justifyContent:"center" }}>
                        <Heatmap { ...(asSECCId !== undefined && seccConfigs[asSECCId]  && seccConfigs[asSECCId][0]) ? seccConfigs[asSECCId][0] : config} />
                </div>
            </div>
    )
})

export default UptimeCalendarChart