import { MultiSelect } from "@mantine/core";

export default function GroupMultiSelect({
  data,
  defaultValue,
  onChange,
}: any) {
  return (
    <MultiSelect
      data={data}
      label="Groups to share this profile with"
      placeholder="Choose group(s)"
      defaultValue={defaultValue}
      onChange={onChange}
      width={"100%"}
    />
  );
}
