import React, { useState, FunctionComponent, useMemo } from "react";
import Checkbox from "@mui/material/Checkbox";
import EmsModal from "@components/modal/emsModal";
import styles from "../globalAdjustment.module.scss";
import { EnableSignalAPI } from "@services";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DisableSigModal: FunctionComponent<DisableSigEventProps> = (props : DisableSigEventProps) => {
    const { t: translation } = useTranslation();
    const [selectAll, setSelectAll] = useState(true);
    const [signalEventsData, setSignalEventsData] = useState<any[]>([]);

    useMemo(() => {
            setSignalEventsData([...props.signalEvents]
                .filter((sEvent) => sEvent.Enabled)
                .map((sEvent) => {
                sEvent.selected = true;
                return sEvent;
            }))
    }, [props.signalEvents])

    const handleCancelClick= () => {
        props.setSigDisableModal(false);
        setSelectAll(true);
        setSignalEventsData([])
    };

    const handleSelectOneClick= (index: number, item: any) => {
        let tempSEvent = [...signalEventsData];
        tempSEvent[index].selected = !item.selected;
        setSignalEventsData(tempSEvent);
    };
    const handleSelectAllClick= () => {
        setSignalEventsData([...signalEventsData.map((item)=>{
            item.selected = !selectAll;
            return item;
        })])
        setSelectAll(!selectAll);
    };

    const handleNextClick = () => {
        if(!signalEventsData  || signalEventsData.length === 0){
            handleCancelClick();
        }
        const filtered_result = signalEventsData.filter(itemA => itemA.selected);

        filtered_result.forEach((sigEvent) => {
            EnableSignalAPI.post(`/system-${sigEvent.SystemId}/signal${props.signalNumber}`, {
                enable: false,
                uuid: sigEvent.Uuid
            })
            .then((res) => {
                props.setSigPendings([sigEvent]);
            })
            .catch((err) => {
                props.setErrorModal(
                    "An error has occurred. \n" +
                    "\nPlease try again. If the issue persists, call \n1-855-567-5853"
                );
                console.error(err);
            });    

        });

        props.setSigPendings([...filtered_result]);
        handleCancelClick();
    };

    return (
        <EmsModal open={props.sigDisableModal} modal_width={"45%"} modal_height={"auto"}>
            <div className={styles.modal_con}>
                <div className={styles.modal_header_row}>
                    <div className={styles.sig_title}>{translation(`globalAdjustment.disableSig${props.signalNumber}Event`)}</div>
                    <div className={styles.event_log__close_button}>
                        <IconButton aria-label="close" onClick={handleCancelClick}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className={styles.selectAllCon}>
                    <div>{translation("globalAdjustment.selectAll")}</div>
                    <Checkbox checked={selectAll} onChange={handleSelectAllClick} />
                </div>
                <div className={styles.modal_body}>
                    <div className={styles.disable_event_list}>
                        {signalEventsData.map((item: any, index: number) => {
                            return (
                                <div className={[styles.modal_row, styles.paramText2].join(" ")} key={index}>
                                    <div> {`${item.Id} - Signal ${item.SignalType} - ${props.currentSystemLabel}` }</div>
                                    <Checkbox checked={item.selected}onChange={() => {handleSelectOneClick(index,item)}}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={[styles.modal_button_row].join(" ")}>
                    <div className={styles.sig_button} onClick={handleCancelClick}> 
                        {translation("buttonLabel.cancel")}
                    </div>
                    <div className={styles.sig_button} onClick={handleNextClick}>
                        {translation("buttonLabel.next")}
                    </div>
                </div>
            </div>

        </EmsModal>
    )
}

export default DisableSigModal;

interface DisableSigEventProps {
    signalNumber : number;
    sigDisableModal: boolean;
    setSigDisableModal: Function;
    setSigPendings: Function;
    signalEvents: any[];
    currentSystemLabel: string;
    setErrorModal: Function;

}
