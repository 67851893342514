import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext } from "react";
import { GroupFields, ShadowFields, useFields } from "./sse";
import {
  AlarmCodes,
  AlertType,
  CostProfile,
  ESS,
  ESSMisc,
  GroupFieldValues,
  Info,
  M1,
  M2,
  M3,
  MeterSummary,
  PCCabinet,
  PCS,
  SECC,
  SECCs,
  Signal1,
  Signal1Response,
  Signal2,
  Signal2Response,
  StringModule,
  StringModules,
} from "./types";

export const FieldContext = React.createContext<GroupFieldValues>({});
export const useFieldContext = () => useContext(FieldContext);

export const useStringModuleContext = (string: number, module: number) => {
  const fields = useFieldContext();

  if (string < 1 || string > 3 || module < 1 || module > 16) return undefined;

  return fields[
    `String${string}Module${module}` as keyof StringModules
  ] as StringModule;
};

function useAllFields() {
  const [q, _, queryKey] = useFields({
    realtime: true,
    group: "all",
  });

  const output = {
    fields: {} as GroupFieldValues,
    queryKey,
  };


  if (!q.isSuccess) return output;
  Object.keys(q.data).forEach((key) =>
    appendToGroupField(key, output.fields, q.data)
  );

  if (!output.fields.Alert) return output;

  return output;
}

export function FieldProvider({ children }: { children: React.ReactNode }) {
  const { fields } = useAllFields();
  return (
    <FieldContext.Provider value={fields}>{children}</FieldContext.Provider>
  );
}

// export function convertField<T extends Omit<GroupFieldValues[keyof GroupFieldValues], "AlertType[]">,U extends keyof T>(group: T, key: U, defaultValue?: T[U]) {
//   const val = group && group[key];
//   if (val === undefined) return defaultValue;
//   return val;
// }

function getJsonFields<T>(records: GroupFields) {
  return Object.keys(records).map((key) => {
    return JSON.parse(records[key].value as string) as T;
  });
}

export function getFieldAsType<T>(records: GroupFields) {
  const output: { [key: string]: any } = {};
  Object.keys(records).forEach((key) => {
    output[key] = records[key].value;
  });
  return output as Partial<T>;
}

function appendToGroupField(
  key: string,
  fields: GroupFieldValues,
  data: ShadowFields
) {
  const re = /String\d+Module\d+/;
  const secc = /SECC-\d+/;
  if (key.search(re) !== -1) {
    fields[key as keyof StringModules] = getFieldAsType<StringModule>(
      data[key]
    );
    return;
  } else if (key.search(secc) !== -1) {
    fields[key as keyof SECCs] = getFieldAsType<SECC>(data[key]);
    return;
  }
  switch (key) {
    case "Alert":
      fields[key] = getJsonFields(data[key]);
      break;
    case "CostProfile":
      fields[key] = getFieldAsType<CostProfile>(data[key]);
      break;
    case "ESS":
      fields[key] = getFieldAsType<ESS>(data[key]);
      break;
    case "ESSMisc":
      fields[key] = getFieldAsType<ESSMisc>(data[key]);
      break;
    case "Info":
      fields[key] = getFieldAsType<Info>(data[key]);
      break;
    case "M1":
      fields[key] = getFieldAsType<M1>(data[key]);
      break;
    case "M2":
      fields[key] = getFieldAsType<M2>(data[key]);
      break;
    case "M3":
      fields[key] = getFieldAsType<M3>(data[key]);
      break;
    case "MeterSummary":
      fields[key] = getFieldAsType<MeterSummary>(data[key]);
      break;
    case "PCCabinet":
      fields[key] = getFieldAsType<PCCabinet>(data[key]);
      break;
    case "PCS":
      fields[key] = getFieldAsType<PCS>(data[key]);
      break;
    case "SECC":
      fields[key] = getFieldAsType<SECC>(data[key]);
      break;
    case "Signal1":
      fields[key] = getFieldAsType<Signal1>(data[key]);
      break;
    case "Signal1Response":
      fields[key] = getFieldAsType<Signal1Response>(data[key]);
      break;
    case "Signal2":
      fields[key] = getFieldAsType<Signal2>(data[key]);
      break;
    case "Signal2Response":
      fields[key] = getFieldAsType<Signal2Response>(data[key]);
      break;
    case "ModbusStatus":
      fields[key] = getJsonFields(data[key]);
      break;
    default:
      console.log("Unknown field type: " + key);
      break;
  }
}

function convertAlarmCode({
  id,
  action_id,
  actionIdByActionId,
  fault_description,
  severity_id,
  severityIdBySeverityId,
  summary,
  title,
  type,
}: any) {
  return {
    id,
    action_id,
    action_description: actionIdByActionId.action_description,
    fault_description,
    severity_id,
    severity_description: severityIdBySeverityId.severity_description,
    summary,
    title,
    type,
  } as AlarmCodes;
}
