import styles from "../../landmarkChargehub.module.scss";
import { systemStatusChargerMap } from "@utils/systemStatus";
import { useDispatch } from "react-redux";
import { setSystemState } from "@store/system";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  
} from "@mui/material";
import { useFields, useSystemId } from "@ecamion/sucrose";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState } from "react";
import { getSystemAlerts } from "@utils/landmarkChargehub";
const LocationList = ({ systems }: LocationListProps) => {
  return (
    <>
      <div className={styles.location_list__title}>All Sites</div>
      <TableContainer style={{ minHeight: "90%" }}>
        <Table
          className={styles.location_list__table}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead className={styles.location_list__header}>
            <TableRow>
              <TableCell>Site</TableCell>
              <TableCell>Unique ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
              {/* <TableCell>Battery</TableCell>
              <TableCell>SOC</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {systems.map((system) => (
              <SystemTableRow system={system} key={system.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const SystemTableRow = ({ system }: { system: LocationInfo }) => {
  const dispatch = useDispatch();
  const [, setSystemId] = useSystemId();
  const [open, setOpen] = useState(false);

  const [ess] = useFields({
    systemId: system.id,
    group: "ESS",
    fields: ["ESSState", "SysSoc"],
    realtime: false,
  });

  const resultAlerts : any = useFields({
    systemId: system.id,
    group: "Alert",
    realtime: false,
    refetchInterval: 60 * 1000
  })?.[0];

  system.alerts = getSystemAlerts(resultAlerts).length ?? 0;

  const { ESSState, SysSoc } = ess.data?.ESS || {};

  const { commissionDate } = system;

  const isCommissioned = () => {
    if (commissionDate === undefined) {
      return false;
    } else {
      let date = new Date();
      if (commissionDate > date) {
        return false;
      }
    }
    return true;
  };

  const connectionStatus = system.alive
    ? "Connected"
    : isCommissioned()
    ? "Disconnected"
    : "Not Commissioned";

  const battery = ESSState
    ? systemStatusChargerMap(ESSState?.value as number)
    : "N/A";

  const handleSelectSystem = () => {
    dispatch(setSystemState({ id: system.id }));
    setSystemId(system.id);
  };

  return (
    <React.Fragment>
      {/* <TableRow () => {

    }}> */}
      <TableRow>
        <TableCell onClick={handleSelectSystem}>{system.siteName} { (system.alerts ?? 0) ? <ReportProblemRoundedIcon style={{ color: "orange", fontSize : "1.2rem" }} /> : <></>}</TableCell>
        <TableCell onClick={handleSelectSystem}>
          {system.fgid || "N/A"}
        </TableCell>
        <TableCell
          onClick={handleSelectSystem}
          className={
            styles[`connection_status__${connectionStatus.toLowerCase()}`]
          }
          title={connectionStatus}
        >
          {connectionStatus === "Connected" ? (
            <CheckCircleIcon />
          ) : connectionStatus === "Disconnected" ? (
            <CancelIcon />
          ) : (
            <DoNotDisturbOnIcon />
          )}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell>{battery}</TableCell>
      <TableCell>{SysSoc ? SysSoc.value : "N/A"}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: "0 0 0 4%",
            borderBottom: open ? "#6c757e solid 1px" : "inherit",
          }}
          colSpan={4}
          onClick={handleSelectSystem}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={styles.collapsible_row}
          >
            <div className={styles.collapsible_info}>
              <b>Unique ID: </b>
              <span>{system.fgid || "N/A"}</span>
            </div>
            <div className={styles.collapsible_info}>
              <b>Status: </b>
              <span className={styles[`connection_status__${connectionStatus.toLowerCase()}`]}>
                {connectionStatus}
              </span>
            </div>
            <div className={styles.collapsible_info}>
              <b>Battery: </b>
              {battery}
            </div>
            <div className={styles.collapsible_info}>
              <b>SOC: </b>
              {SysSoc ? SysSoc.value : "N/A"}
            </div>
            <div className={styles.collapsible_info}>
              <b>Alerts: </b>
              <span>{system.alerts}</span>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

interface LocationInfo {
  id: number;
  siteName?: string;
  fgid?: string;
  commissionDate?: Date;
  alive: boolean;
  alerts?: number;
}

interface LocationListProps {
  systems: LocationInfo[];
}

export default LocationList;
