import { FunctionComponent } from "react";
import classes from "./headBar.module.scss";
import { useSelectedSystem } from "@ecamion/sucrose";
import { useFields } from "@ecamion/sucrose";

const HeadBar: FunctionComponent<HeadBarProps> = (props) => {
  const { id, storeId, siteName, fgid, alive } = useSelectedSystem() || {};

  const [fields] = useFields({
    realtime: true,
    fields: ["ESSState"],
    group: "ESS",
  });

  const ess = fields.data?.ESS?.ESSState?.value as number | undefined;
  const essState = ess === undefined ? -1 : ess;

  const storeName =
    siteName +
    (storeId || fgid
      ? ` Store # ${storeId !== undefined ? storeId : "--"}${
          fgid ? ` (${fgid})` : ""
        }`
      : ` System # ${id === undefined ? "--" : id}`);

  return (
    <div className={classes.headbarContainer}>
      <div className={classes.headbarTitle}>
        <h2 className={classes.headbarTitle1}>{props.title}​</h2>
        <h4 className={classes.headbarTitle2}>{storeName}</h4>
      </div>

      <div className={classes.headbarAlerts_con}></div>

      <div className={classes.headbaritemList}>
        <div className={classes.ChargeHubConnect}>
          <div
            style={{
              color: `${essState > -1 ? "#368736" : "#ea9c00"}`,
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {alive ? "ChargeHub Connected" : "ChargeHub Disconnected"}
          </div>
          <div
            style={{
              display: "flex",
              height: "50%",
              marginLeft: "5%",
              width: "80%",
              justifyContent: "space-around",
              alignItems: "center",
              gap: "5%",
            }}
          >
            <div style={{ fontSize: "0.9vw", fontWeight: 500 }}>Mode:</div>
            <div
              style={{
                fontSize: "0.9vw",
                fontWeight: 500,
                color: `${
                  alive && [2, 3, 4].includes(essState) ? "#368736" : "#ea9c00"
                }`,
                marginLeft: "3%",
                minWidth: "70%",
              }}
            >
              {alive && [2, 3, 4].includes(essState)
                ? "System Operational"
                : alive
                ? "Maintenance"
                : "----"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface HeadBarProps {
  title: string;
}

export default HeadBar;
