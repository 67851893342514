import { FunctionComponent } from "react";
import Map, { Marker, NavigationControl } from "react-map-gl";
import styles from "./map.module.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch } from "react-redux";
import { setSystemState } from "@store";
import { useFields, useSystemId } from "@ecamion/sucrose";
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import LensIcon from '@mui/icons-material/Lens';
import { getSystemAlerts } from "@utils/landmarkChargehub";

const LocationMap: FunctionComponent<MapProps> = ({
  selected,
  systems,
}: MapProps) => {
  const orderedSystems = selected
    ? [...systems.filter((s) => s.id !== selected.id), selected]
    : systems;

  return (
    <Map
      initialViewState={{
        latitude: parseInt(process.env.REACT_APP_MAPBOX_DEFAULT_LATITUDE || ""),
        longitude: parseInt(
          process.env.REACT_APP_MAPBOX_DEFAULT_LONGITUDE || ""
        ),
        zoom: parseInt(process.env.REACT_APP_MAPBOX_DEFAULT_ZOOM_LEVEL || ""),
      }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      attributionControl={false}
    >
      {orderedSystems.map((system: MarkerProps, index: number) => {
        return (SystemMarker(system,selected));
      })}

      <NavigationControl />
    </Map>
  );
};


const SystemMarker = (system : MarkerProps, selected: MarkerProps | undefined) =>{

  const getMarkerColor = (site: MarkerProps) => {

    if (site.alive) {
      if((site.alerts ?? 0) >0){
        return "orange"
      }
      return "green";
    } else if (!site.commissionDate || site.commissionDate > new Date()) {
      return "grey";
    }
    return "red";
  };

  const dispatch = useDispatch();
  const [, setSystemId ] = useSystemId();
  
  const resultAlerts : any = useFields({
    systemId: system.id,
    group: "Alert",
    realtime: false,
    refetchInterval: 60 * 1000
  })?.[0];

  
  system.alerts = getSystemAlerts(resultAlerts).length ?? 0;

  return <Marker
      key={system.id}
      longitude={system.longitude}
      latitude={system.latitude}
      anchor="bottom"
      onClick={() => {
        dispatch(setSystemState({ id: system.id }));
        setSystemId(system.id);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>

      <PlaceSharpIcon className={[styles[getMarkerColor(system)], styles[system.id === selected?.id ? "selected" : "marker"] ].join(" ")}></PlaceSharpIcon>
      {/*this icon creates the ilusion of having a white background on the marker */}
      <LensIcon className={styles.back_circle}></LensIcon>
    </Marker>
}

export default LocationMap;
export interface MarkerProps {
  id: number;
  commissionDate?: Date;
  siteName?: string;
  longitude?: number;
  latitude?: number;
  alive?: boolean;
  alerts?: number;
}

interface MapProps {
  selected?: MarkerProps;
  systems: MarkerProps[];
}

