import { FunctionComponent } from "react";
import {
  pcDoorStatusConvert,
  telecomDoorStatusConvert,
} from "@utils/siteOverview";
import { Activity } from "@controllers";
import HeadBar from "@components/headBar";
import styles from "./siteOverview.module.scss";
import dashboard_page from "@view/dashboard_page2.png";
import { useTranslation } from "react-i18next";
import { useSelectedSystem } from "@ecamion/sucrose";
import { useFieldContext } from "@services/sse/fields";
import { MeterSummary, SECCs } from "@services/sse/types";

const SiteOverview: FunctionComponent = () => {
  const { t: translation } = useTranslation();

  const fields  = useFieldContext();
  const { alive } = useSelectedSystem() || {};

  const { ESS, PCCabinet, MeterSummary } = fields;

  const jules = [1, 2, 3, 4].map((jule) => {
    const k = ("SECC-" + jule) as keyof SECCs;
    return fields[k] ? fields[k]?.MeterPresentPower || 0 : 0;
  });

  const [site, chargehub, telecom] = [
    "BuildingWPower",
    "ChargehubWPower",
    "TelecomWPower",
  ].map((key) => {
    return MeterSummary ? MeterSummary[key as keyof MeterSummary] || 0 : 0;
  });

  const powerLines = getPowerLines(
    site,
    chargehub,
    telecom,
    jules.reduce((a, b) => a + b, 0)
  );

  const powerLabel = (key: keyof MeterSummary) => {
    const m = MeterSummary ? MeterSummary[key] : undefined;
    return m === undefined ? "-- kW" : `${(m/1000).toFixed(0)} kW`;
  };

  const julePowerLabel = (jule: number) => {
    const k = ("SECC-" + jule) as keyof SECCs;
    const m = fields[k] ? fields[k]?.MeterPresentPower : undefined;
    return m === undefined ? "-- kW" : `${(m/1000).toFixed(1)} kW`;
  };

  const setDoorColor = (door?: number, door2?: number) => {
    return door === 3 && (door2 === undefined || door2 === 3)
      ? "#3c883a"
      : "#ff0000";
  };

  const setDoorText = (door?: number, door2?: number) => {
    return door === 3 && (door2 === undefined || door2 === 3)
      ? "Closed"
      : "Open";
  };

  let display = (
    <div className={styles.body_con}>
      <div className={styles.buttonA}>
        <div className={styles.buttonA_item}>
          <p className={styles.buttonA_item_text}>ESS Door:</p>
          <div
            style={{
              color: `${ESS?.SysDoorStatus || 0 ? "#3c883a" : "#ff0000"}`,
            }}
          >
            {ESS?.SysDoorStatus || 0 ? "Close" : "Open"}
          </div>
        </div>
        <div className={styles.buttonA_item}>
          <div className={styles.buttonA_item_text}>PC Doors:</div>
          <div
            style={{
              color: `${
                pcDoorStatusConvert(PCCabinet?.PCDoorStatus || 0)
                  ? "#3c883a"
                  : "#ff0000"
              }`,
            }}
          >
            {pcDoorStatusConvert(PCCabinet?.PCDoorStatus || 0)
              ? "Close"
              : "Open"}
          </div>
        </div>
        <div className={styles.buttonA_item}>
          <div className={styles.buttonA_item_text}>Telecom Door:</div>
          <div
            style={{
              color: `${
                telecomDoorStatusConvert(PCCabinet?.PCDoorStatus || 0)
                  ? "#3c883a"
                  : "#ff0000"
              }`,
            }}
          >
            {telecomDoorStatusConvert(PCCabinet?.PCDoorStatus || 0)
              ? "Close"
              : "Open"}
          </div>
        </div>
      </div>

      <div className={styles.site_main_left_container}>
        <img alt={""} src={dashboard_page} className={styles.dashboard_page} />

        <div style={{ position: "absolute", top: "30%", left: "5%" }}>
          <div className={styles.SiteOverview_object_title}>
            {translation("siteOverview.electricalUtility(Grid)")}
          </div>
          <div>{powerLabel("BuildingWPower")}</div>
        </div>

        <div style={{ position: "absolute", top: "32%", left: "48%" }}>
          <div className={styles.SiteOverview_object_title}>ChargeHub</div>
          <div>{powerLabel("ChargehubWPower")}</div>
        </div>

        <div style={{ position: "absolute", top: "70%", left: "5%" }}>
          <div className={styles.SiteOverview_object_title}>Facility</div>
          <div>{powerLabel("FacilityWPower")}</div>
        </div>

        <div style={{ position: "absolute", top: "60%", right: "7%" }}>
          <div className={styles.SiteOverview_object_title}>Telecom</div>
          <div>{powerLabel("TelecomWPower")}</div>
        </div>

        <div className={styles.charger_con}>
          {[1, 2, 3, 4].map((i) => (
            <div key={i}>
              <div className={styles.SiteOverview_object_title}>Jule {i}</div>
              <div>{julePowerLabel(i)}</div>
            </div>
          ))}
        </div>

        {powerLines}
      </div>
    </div>
  );

  return (
    <div className={styles.root_container}>
      <HeadBar title="Dashboard" />
      <div className={styles.site_main_container}>
        {alive ? (
          display
        ) : (
          <div className={styles.body_con} style={{ fontSize: "4vw" }}>
            Disconnected
          </div>
        )}
        <div className={styles.activity_container}>
          <Activity />
        </div>
      </div>
    </div>
  );
};

function getPowerLines(
  site: number,
  chargehub: number,
  telecom: number,
  jule: number
) {
  const mappings = {
    site: {
      con: [styles.l1_A_con, styles.l1_B_con, styles.l1_C_con],
      item: [styles.l1_A_item, styles.l1_B_item, styles.l1_B_item],
    },
    chargehub: {
      con: [styles.l3_A_con, styles.l3_C_con, styles.l3_B_con],
      item: [styles.l3_A_item, styles.l3_C_item, styles.l3_B_item],
      item_neg: [
        styles.l3_A_item_neg,
        styles.l3_C_item_neg,
        styles.l3_B_item_neg,
      ],
    },
    telecom: {
      con: [styles.l2_A_con, styles.l2_B_con],
      item: [styles.l2_A_item, styles.l2_B_item],
    },
    jule: {
      con: [styles.l4_A_con, styles.l4_B_con, styles.l4_C_con],
      item: [styles.l4_A_item, styles.l4_B_item, styles.l4_C_item],
    },
  };

  let siteLines: JSX.Element[] = [];

  if (site < 0)
    siteLines = mappings.site.con.map((item, index) => (
      <div
        key={index + "line"}
        className={item}
        style={{ backgroundColor: "red" }}
      ></div>
    ));
  else if (site > 0)
    siteLines = mappings.site.con.map((item, index) => (
      <div key={index + "line"} className={item}>
        <div className={mappings.site.item[index]}></div>
      </div>
    ));
  else
    siteLines = mappings.site.con.map((item, index) => (
      <div key={index + "line"} className={item}></div>
    ));

  let offset = mappings.site.con.length;

  let chargehubLines: JSX.Element[] = [];
  if (chargehub < 0)
    chargehubLines = mappings.chargehub.con.map((item, index) => (
      <div key={index + offset + "line"} className={item}>
        <div className={mappings.chargehub.item[index]}></div>
      </div>
    ));
  else if (chargehub > 0)
    chargehubLines = mappings.chargehub.con.map((item, index) => (
      <div key={index + offset + "line"} className={item}>
        <div className={mappings.chargehub.item_neg[index]}></div>
      </div>
    ));
  else
    chargehubLines = mappings.chargehub.con.map((item, index) => (
      <div key={index + offset + "line"} className={item}></div>
    ));

  offset += mappings.chargehub.con.length;
  let telecomLines: JSX.Element[] = [];
  if (telecom > 0)
    telecomLines = mappings.telecom.con.map((item, index) => (
      <div key={index + offset + "line"} className={item}>
        <div className={mappings.telecom.item[index]}></div>
      </div>
    ));
  else
    telecomLines = mappings.telecom.con.map((item, index) => (
      <div key={index + offset + "line"} className={item}></div>
    ));

    offset += mappings.telecom.con.length;
  let juleLines: JSX.Element[] = [];
  if (jule > 0)
    juleLines = mappings.jule.con.map((item, index) => (
      <div key={index + offset + "line"} className={item}>
        <div className={mappings.jule.item[index]}></div>
      </div>
    ));
  else
    juleLines = mappings.jule.con.map((item, index) => (
      <div key={index + offset + "line"} className={item}></div>
    ));

  return [...siteLines, ...chargehubLines, ...telecomLines, ...juleLines];
}

export default SiteOverview;
