import { BiAnalyse, BiBoltCircle } from "react-icons/bi";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";
import { AiFillTag } from "react-icons/ai";
import { HiReceiptTax } from "react-icons/hi";
import { FiCodesandbox } from "react-icons/fi";
import { MdOutlineTag } from "react-icons/md";
import { BsCurrencyExchange } from "react-icons/bs";

export type SpecType = "number" | "text";

interface Spec {
  icon: any;
  readOnly: boolean;
  prefix: string;
  suffix: string;
  title?: string;
  type: SpecType;
}

type Specs = {
  [key: string]: Spec;
};

export function ConvertToTitle(key: string) {
  return key
    .split("_")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

const CostProfileSpec: Specs = {
  name: {
    icon: <AiFillTag />,
    readOnly: false,
    prefix: "",
    suffix: "",
    type: "text",
  },
  id: {
    icon: <MdOutlineTag />,
    readOnly: true,
    prefix: "",
    suffix: "",
    type: "number",
  },
  version: {
    icon: <FiCodesandbox />,
    readOnly: true,
    prefix: "",
    suffix: "",
    type: "number",
  },
  price_currency: {
    icon: <BsCurrencyExchange />,
    readOnly: false,
    prefix: "",
    suffix: "",
    type: "text",
  },
  tax_rate: {
    icon: <HiReceiptTax />,
    readOnly: false,
    prefix: "",
    suffix: "%",
    type: "number",
    title: "Harmonized Tax Rate",
  },
  connection_fee: {
    icon: <BiBoltCircle />,
    readOnly: false,
    prefix: "$",
    suffix: "",
    type: "number",
  },
  curtail_fee: {
    icon: <BiAnalyse />,
    readOnly: false,
    prefix: "$",
    suffix: "/min",
    type: "number",
    title: "Eco-Power Pricing",
  },
  curtail_energy_max: {
    icon: <BiAnalyse />,
    readOnly: false,
    prefix: "",
    suffix: "kWh",
    type: "number",
    title: "Signal 2 Max Charge",
  },
  speed_1_power: {
    icon: <RiNumber1 />,
    readOnly: false,
    prefix: "",
    suffix: "kW",
    type: "number",
  },
  speed_1_fee: {
    icon: <RiNumber1 />,
    readOnly: false,
    prefix: "$",
    suffix: "/min",
    type: "number",
    title: "Speed 1 Default Rate",
  },
  speed_2_power: {
    icon: <RiNumber2 />,
    readOnly: false,
    prefix: "",
    suffix: "kW",
    type: "number",
  },
  speed_2_fee: {
    icon: <RiNumber2 />,
    readOnly: false,
    prefix: "$",
    suffix: "/min",
    type: "number",
    title: "Speed 2 Default Rate",
  },
  speed_3_power: {
    icon: <RiNumber3 />,
    readOnly: false,
    prefix: "",
    suffix: "kW",
    type: "number",
  },
  speed_3_fee: {
    icon: <RiNumber3 />,
    readOnly: false,
    prefix: "$",
    suffix: "/min",
    type: "number",
    title: "Speed 3 Default Rate",
  },
};

export default CostProfileSpec;

export const CostProfileSample = {
  version: 11,
  id: 1,
  price_currency: "CAD",
  name: "",
  tax_rate: 13,
  connection_fee: 1,
  curtail_fee: 0.05,
  curtail_energy_max: 15,
  speed_1_power: 75,
  speed_1_fee: 0.1,
  speed_2_power: 125,
  speed_2_fee: 0.2,
  speed_3_power: 150,
  speed_3_fee: 0.3,
};
