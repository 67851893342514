import { IoTV2API } from "@services";
import store, { setActiveCostProfile, setCostProfiles } from "@store";

export const renameKey = (o: any, oldKey: string, newKey: string) => {
  o.forEach((x: any) => {
    delete Object.assign(x, { [newKey]: x[oldKey] })[oldKey];
  });
};

export const handleError = (error: any, enqueueSnackbar: any) => {
  if (error.response) {
    enqueueSnackbar(error.response.data.message, {
      autoHideDuration: 4000,
      variant: "error",
    });
  }
};

export const handleSuccess = (enqueueSnackbar: any, text = "Success") => {
  enqueueSnackbar(text, { autoHideDuration: 3000, variant: "success" });
};

export const deleteKeys = (o: any, keys: string[]) => {
  if (!o) {
    return {};
  }
  const copy = { ...o };
  keys.forEach((key) => {
    if (copy.hasOwnProperty(key)) {
      delete copy[key];
    }
  });
  return copy;
};

export const costProfileToJsonObj = (costProfile: any) => {
  return deleteKeys(costProfile, [
    "group_ids",
    "removable",
    "modifiable",
    "creation_time",
    "last_modified_time",
  ]);
};

export const editableCostProfileToJsonObj = (costProfile: any) => {
  return deleteKeys(costProfile, [
    "removable",
    "modifiable",
    "creation_time",
    "last_modified_time",
    "group_ids",
    "version",
    "id",
  ]);
};

export const updateCostProfiles = () => {
  IoTV2API.get("cost-profile").then((response) => {
    store.dispatch(setCostProfiles(response.data));
  });
};

export const updateActiveCostProfile = (systemId: number) => {
  IoTV2API.get("active-cost-profile/" + systemId).then((response) => {
    store.dispatch(setActiveCostProfile(response.data));
  });
};

// There's an issue with processing redis in lambda. We don't know when the lambda finishes.
// This means, e.g., the client applies the cost profile, then the frontend needs to know if the user can edit the profile that was previously active on that system.
// Both backend and frontend don't know when the lambda finishes. It's possible to solve this by directly setting redis in the REST API server.
// However, this solution hasn't been implemented yet. Let's hope this works.
// Even if it doesn't, the user backend should still be able to check it if the table includes some actions that should be greyed out.
export const sleepThenUpdate = async () => {
  await new Promise((resolve) => setTimeout(resolve, 250));
  updateCostProfiles();
};
