export const getUTCDateStrRep = (dt: Date) => {
  return `${dt.getUTCFullYear()}-${(dt.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dt.getUTCDate().toString().padStart(2, "0")}`;
};

export const getLocalDateStrRep = (dt: Date) => {
  return `${dt.getFullYear()}-${(dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
}

export const getDaysArray = (start:any , end: any) => {
  const dates = [];
  const startDT = new Date(start);
  const endDT = new Date(end);
  while (startDT <= endDT) {
    dates.push(getUTCDateStrRep(startDT));
    startDT.setDate(startDT.getDate() + 1);
  }
  return dates;
};

export const parseDate = (dateString: string) => {
  const parts = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
  return parts?.map((val, index) => {
    if (index === 0) {
      return val;
    } else if (index === 2) {
      return parseInt(val) - 1;
    } else {
      return parseInt(val);
    }
  });
};

export const getCurrDate = () => {
  return new Date().toLocaleDateString();
}

export const sortProfileConfig = (prev: any, curr: any) => {
  if ( prev.profile_name < curr.profile_name ){
    return -1;
  }
  if ( prev.profile_name > curr.profile_name ){
    return 1;
  }
  return 0;
}

export const getDateTime = (time: any) => {
  const now = new Date(time);
  const year =
    String(now.getFullYear()).length === 1
      ? "0" + String(now.getFullYear())
      : String(now.getFullYear());
  const month =
    String(now.getMonth() + 1).length === 1
      ? "0" + String(now.getMonth() + 1)
      : String(now.getMonth() + 1);
  const day =
    String(now.getDate()).length === 1
      ? "0" + String(now.getDate())
      : String(now.getDate());
  const hour =
    String(now.getHours()).length === 1
      ? "0" + String(now.getHours())
      : String(now.getHours());
  const minute =
    String(now.getMinutes()).length === 1
      ? "0" + String(now.getMinutes())
      : String(now.getMinutes());
  const second =
    String(now.getSeconds()).length === 1
      ? "0" + String(now.getSeconds())
      : String(now.getSeconds());
  const dateTime =
    month + "/" + day + "/" + year + " " + hour + ":" + minute + ":" + second;
  return dateTime;
};

export const getLocalTimeStrRep = (dateTime: any) => {
  return new Date(dateTime).toLocaleTimeString("en", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

/**
 * Function to check if a given variable is a numeric value or not
 * 
 * @param {any} n - The variable to check
 * @returns {boolean} - Returns true if the variable is numeric, false otherwise
 */
export const isNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * returns the date as a unix timestamp in seconds
 * @param dateTime datetime to be converted
 * @returns unix timestamp integer
 */
export const getUnixTimestamp = (dateTime: any) : number => {
  return parseInt((new Date(dateTime).getTime() / 1000).toFixed(0))
};

/**
 * Returns the initial date in a time range or
 * Returns the final date in a time range
 * @param dateTime Date object or string
 * @param dayStart if the date is the initial, it will set the time as 00:00:00.000 otherwise 29:59:59.999
 * @returns The date with fixed hours
 */
export const getDateWithFixedHours = (dateTime: Date | string, dayStart : boolean = false) : Date => {
  
  if(dayStart){
    return new Date(new Date(dateTime).setHours(0,0,0,0))
  }
  return new Date(new Date(dateTime).setHours(23,59,59,999))

};

/**
 * Formats a given date string to a short month, 2-digit day, and numeric year format
 * @param {string} dateTime - A string representing a date that will be formatted
 * @returns {string} - A formatted date string in the format of "short month day, year"
 */
export const formatDate = (dateTime: string) => {
  // create a new Date object from the dateTime string
  var date = new Date(dateTime);
  // check if the date is a valid date
  if (!isNaN(date.getTime())) {
    // create a new Intl.DateTimeFormat object with options for short month, 2-digit day, and numeric year
    const formatter = new Intl.DateTimeFormat("en", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    // format the date using the formatter
    const formattedDate = formatter.format(date);
    // return the formatted date
    return formattedDate;
  }
  return null;
};


export const getIdFromSystemName = (systemName : string) : number => {
  const systemNameParts = parseInt(systemName?.split("-")?.at(-1) ?? "-1") ;
  return systemNameParts;
}


export const getDateTimeFromTodayHours = (time: string) => {
  const now = new Date();
  const [hours,minutes] = time.split(":");
  return new Date(now.setHours(parseInt(hours),parseInt(minutes), 0, 0));
}


export const compareDates = (dateA: string | Date ,dateB: string | Date ) =>{
  const dateANumber  = new Date(dateA).getTime();
  const dateBNumber  = new Date(dateB).getTime();

  return dateANumber > dateBNumber;

}

export const convertToYYYYMMDD = (date: Date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}
